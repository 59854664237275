// 全局环境变量
const env = GLOBAL_ENV;

console.log("globalEnv", env);

export default {
  API_HOST: env.API_HOST,
}

/**
 * 区域信息
 */
export const areasInfo = [
  {
    en: 'China Mainland',
    cn: '中国大陆',
    th: 'จีนแผ่นดินใหญ่',
    code: '+86',
    image: 'assets/images/login/banner_zh.png',
    length: '11', // 限制手机号长度
    reg: '^[1][3-9]\\d{9}$', // 手机号匹配正则
  },
  // {
  //   en: 'China Hongkong',
  //   cn: '中国香港',
  //   th: 'จีนฮ่องกง',
  //   code: '+852',
  //   length: '8',
  //   image: 'assets/images/login/banner_hk.png',
  //   reg: '^([6|9])\\d{7}$',
  // },
  // {
  //   en: 'China Taiwan',
  //   cn: '中国台湾',
  //   th: 'ไต้หวันจีน',
  //   code: '+886',
  //   length: '10',
  //   reg: '^[0][9]\\d{8}$',
  //   image: 'assets/images/login/banner_tw.png',
  // },
  // {
  //   en: 'China Macao',
  //   cn: '中国澳门',
  //   th: 'มาเก๊าจีน',
  //   code: '+853',
  //   length: '8',
  //   reg: '^[6]\\d{7}$',
  //   image: 'assets/images/login/banner_mc.png',
  // },
  {
    en: 'Thailand',
    cn: '泰国',
    th: 'ประเทศไทย',
    code: '+66',
    length: '8,9,10',
    image: 'assets/images/login/banner_th.png',
  },
  // {
  //   en: 'Singapore',
  //   cn: '新加坡',
  //   th: 'สิงคโปร์',
  //   code: '+65',
  //   length: '8',
  //   image: 'assets/images/login/banner_sp.png',
  // },
  // {
  //   en: 'Vietnam',
  //   cn: '越南',
  //   th: 'เวียดนาม',
  //   code: '+84',
  //   length: '10',
  //   image: 'assets/images/login/banner_vm.png',
  // },
  // {
  //   en: 'Philippines',
  //   cn: '菲律宾',
  //   th: 'ฟิลิปปินส์',
  //   code: '+63',
  //   length: '10',
  //   image: 'assets/images/login/banner_pp.png',
  // },
];
