import axios from "axios";
import constant from "../utils/constant";

const host = constant.API_HOST + "/applet";

const apiManager = axios.create({
  baseURL: host,
  timeout: 10000,
  withCredentials: false,
  headers: {
    "X-AppId": "wx30d3ab7b2554dcf1",
    "X-Country": "th",
    "X-CURRENCY": "CNY",
    "X-Language": "zh-CN",
    "X-Platform": "appCosApplet",
    "X-Version": "1.0.0",
  },
});

/** 请求拦截器 **/
apiManager.interceptors.request.use(
    (config) => {
      const { contentType, responseType } = config;
      if (contentType === 'formData') {
        config.headers['Content-type'] = 'multipart/form-data';
      }
      if (responseType) {
        config.headers['responseType'] = responseType;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

export default apiManager;
