import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

const PlayMap = lazy(() => import('src/pages/playMap'));
const DemandForm = lazy(() => import('src/pages/demandForm'));
const ArticlePage = lazy(() => import('src/pages/article/[id]'));
const ActivityGround = lazy(() => import('src/pages/activityGround/[id]'));

const routers = createBrowserRouter([
  {
    path: '/',
    element: <div />,
  },
  {
    path: '/playMap',
    element: <PlayMap />,
  },
  {
    path: '/p/:id',
    element: <ArticlePage />,
  },
  {
    path: '/demandForm',
    element: <DemandForm />,
  },
  {
    path: '/needsForm',
    element: <DemandForm />,
  },
  {
    path: '/activityGround/:id',
    element: <ActivityGround />,
  }
]);

export default routers;
