import apiManager from '../index';

export function getWxConfig(option) {
  const { url } = option;
  return apiManager({
    url: '/api/v1/ofa/info',
    method: 'post',
    data: {
      value: url,
    },
  }).then((res) => res.data);
}

export function mapInfo(categoryId) {
  return apiManager({
    url: '/api/v1/map/list',
    method: 'post',
    data: { categoryId },
  }).then((res) => res.data ?? {});
}

export function mapCategory() {
  return apiManager({
    url: '/api/v1/mapCategory/list',
    method: 'post',
    data: {},
  }).then((res) => res.data ?? {});
}

export function getBaseConfig(data) {
  return apiManager({
    url: '/api/v1/baseConfig/value',
    method: 'post',
    data,
  }).then((res) => res.data ?? {});
}

export function getArticle(articleKey, params) {
  return apiManager({
    url: `/api/v1/article/${articleKey}`,
    params,
  }).then((res) => res.data);
}

export function getQuestionnaire(questionnaireKey, time) {
  return apiManager({
    url: `/api/v1/questionnaire`,
    params: { questionnaireKey, time },
  }).then((res) => res.data);
}

export function postQuestionnaire(questionnaireKey) {
  return apiManager({
    url: `/api/v1/questionnaire`,
    method: 'post',
    data: { questionnaireKey },
  }).then((res) => res.data);
}

export function questionnaireSave(data) {
  return apiManager({
    url: `/api/v1/questionnaire/answer/save`,
    method: 'post',
    data,
  }).then((res) => res.data);
}

export function groundCreate(data) {
  return apiManager({
    url: `/api/v1/activityGround/create`,
    method: 'post',
    data,
  }).then((res) => res.data);
}

const api = {
  getWxConfig,
  mapInfo,
  mapCategory,
  getBaseConfig,
  getArticle,
  getQuestionnaire,
  postQuestionnaire,
  questionnaireSave,
  groundCreate,
};

export default api;
