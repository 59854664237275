import { useLayoutEffect, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import routers from './routers';
import { RouterProvider } from 'react-router-dom';
import initial from './services/wx.sdk';
import './index.scss';

export const App = () => {
  useLayoutEffect(() => {
    initial();
  }, []);
  return (
    <Suspense fallback="...">
      <RouterProvider router={routers} />
    </Suspense>
  );
};

createRoot(document.getElementById('root')).render(<App />);
